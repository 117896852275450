<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes">
    <form @submit.prevent>
      <div class="form-group">
        <input type="text" v-model="searchValue" class="form-control mb-4" placeholder="Search by name or paste address" @keyup="filterArrayTokens()">
      </div>
    </form>
    <div class="TokensList">
      <div v-if="tokensList.length <= 0" class="alert alert-info text-center mb-0">Token not found on current chain.<br/> <span class="font-size-080">Try changing your search query, or switch to another Network</span></div>
      <template v-for="(token,index) in tokensList" v-bind:key="index">
        <div v-if="(origin == 'to' && token.address == tokenFromSelected.address) || (origin == 'from' && token.address == tokenToSelected.address)" class="mb-3 d-flex align-items-center justify-content-between text-muted"
        >
          <div class="d-inline-flex">
            <div class="mr-2">
              <img :src="token.logo" alt="" width="40" height="40"/>
            </div>
            <div class="text-muted">  
              <p class="m-0 font-weight-bold">{{ token.symbol }}</p>
              <p class="text-muted m-0 font-size-080">{{ token.name }}</p>
            </div>
          </div>
          <div>
            <span class="font-weight-bold">{{ $formatNumber(token.balance) }}</span>
          </div>
        </div>
        <div v-else class="token d-flex align-items-center justify-content-between mb-3" @click="selectToken(token)">
          <div class="d-inline-flex">
            <div class="mr-2">
              <img :src="token.logo" alt="" width="40" height="40"/>
            </div>
            <div>  
              <p class="m-0 font-weight-bold">{{ token.symbol }}</p>
              <p class="text-muted m-0 font-size-080">{{ token.name }}</p>
            </div>
          </div>
          <div>
            <span class="font-weight-bold">{{ $formatNumber(token.balance) }}</span>
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>

import axios from 'axios';
import Modal from '../../../Shared/Modal.vue';
import swapTokens from '../../../../../data/swapTokens.json'
import chains from '../../../../../data/chains.json'

export default {
  name: 'Home',
  props: [
    'id',
    'modalTitle',
    'class',
    'index',
    'origin',
    'chain',
    'tokenFromSelected',
    'tokenToSelected'
  ],
  data() {
    return {
      availablesTokens: swapTokens.tokens[this.chain.id],
      accounts: '',
      searchValue: '',
      importedTokens: '',
      chainSelected: '',
      tokensList: swapTokens.tokens[this.chain.id],
      
    };
  },
  components: {
    Modal
  },
  updated(){
    if(this.$checkMetamask()){
      this.availablesTokens = swapTokens.tokens[this.chain.id]
      this.tokensList = swapTokens.tokens[this.chain.id]
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result;
        this.getBalanceTokensList();
      });
      this.setChain();
    }
  },
  created() {
    /*axios.get(`/v2/tokens/list?chain=bsc`)
      .then((response)=> {
        this.availablesTokens = response.data.body.tokens
        this.$emit('widgetLoaded');
      });*/
    if(this.$checkMetamask()){
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result;
        this.getBalanceTokensList();
      });
    }
  },
  methods: {
    selectToken(token){

      let emitToSend = {
        'token': token,
        'origin': this.origin
      }

      this.$emit('tokenSelected',emitToSend);
      this.closeModal();
    },
    closeModal(){
      document.querySelector('#TokensList .close').click();
    },
    getChainId(){
      return window.ethereum.request({
        method: 'eth_chainId',
      });
    },
    async setChain(){
      let currentMetamaskNetwork = await this.getChainId();
      let metamaskChain = chains.filter((chain) => chain.chain == currentMetamaskNetwork)
      console.log(metamaskChain[0]);
      this.chainSelected = metamaskChain[0];
    },
    async getBalanceTokensList(){
      for(let i = 0; i < this.tokensList.length; i++){
        this.tokensList[i].balance = await this.$getToken(this.tokensList[i],this.accounts[0]);
      }
      this.tokensList.sort((firstItem, secondItem) => secondItem.balance - firstItem.balance);
    },
    filterArrayTokens(){

      if(this.search == '') { 
        this.tokensList = this.availablesTokens
      }

      else if(this.searchValue.startsWith('0x')){
        axios.get(`/cryptos/symbol-info?contract=${this.searchValue}`)
          .then((response)=> {
            if(response.data.body.status.error_code != 0){
              this.tokensList = [];
            }
            else{
              let token = response.data.body.data[Object.keys(response.data.body.data)[0]]
              this.tokensList = [];
              token.contract_address.forEach((contract)=>{

                if(contract.platform.coin.slug == this.chainSelected.coinmarketcapSlug && contract.contract_address == this.searchValue ){
                  console.log(contract);
                  let tokenToSend = [{
                    "address": this.searchValue,
                    "name": token.name,
                    "symbol": token.symbol,
                    "decimals": 18,
                    "decimalsDisplay": 4,
                    "logo":"https://s2.coinmarketcap.com/static/img/coins/64x64/"+token.id+".png"
                  }]

                  this.tokensList = tokenToSend;
                  this.getBalanceTokensList();
                }
              })
            }
            
          });
      }

      else{
        let list = this.availablesTokens.filter((token) => token.name.toLowerCase().includes(this.searchValue.toLowerCase()) || token.symbol.toLowerCase().includes(this.searchValue.toLowerCase()))
        this.tokensList = list;

      }
      this.getBalanceTokensList();
    },
    clearSearch(){
      this.searchValue = '';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .TokensList{
    max-height:300px;
    overflow-y:auto;

    .token{
      cursor:pointer;
      &:hover,&:active,&:focus{
        background:#f5f5f5;
      }
    }
  }
</style>
